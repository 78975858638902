import React, { useState } from "react";

import timelineData from './timeline-data';
import TimelineSections from "./TimelineSections";

import style from './Timeline.module.css';

const Timeline = () => {
  const [showHiddenTimeline, setShowHiddenTimeline] = useState(false);

  let timelineItemsCountOne = 0;
  const timelineItemsOne = timelineData.map((data, index) => {
    timelineItemsCountOne++;
    if(timelineItemsCountOne >= 5){
      return "";
    }
    return (<TimelineSections key={index} item_data={data} />)
  });

  let timelineItemsCountTwo = 0;
  const timelineItemsTwo = timelineData.map((data2, index2) => {
    timelineItemsCountTwo++;
    if(timelineItemsCountTwo >= 5){
      return (<TimelineSections key={index2} item_data={data2} />);
    }
    return "";
  });

  const showHiddenTimelineHandler = () => {
    setShowHiddenTimeline(true);
  };

  return (
    <div id="timeline" className={style.timeline}>
      <div className={style['timeline-wrap']}>
        { timelineItemsOne }
        { 
          showHiddenTimeline && timelineItemsTwo
        }
        {
          showHiddenTimeline || (<button className={`${style['timeline__showmore']} dark-theme_timeline`} onClick={showHiddenTimelineHandler}>Show more...</button>)
        }
      </div>
    </div>
  );
};

export default Timeline;