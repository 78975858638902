import React, { useState } from 'react';

import worksData from './works-data';
import WorkItems from './WorkItems';
import Spacer from '../../ui/Spacer';

import style from './Works.module.css';

// const convertToIdFriendly = (str) => {
//   return str.toLowerCase().replace(/[^A-Z0-9]+/ig, "_");
// };

const Works = () => {
  const [workItemsFilter, setWorkItemsFilter] = useState(null);
  let categories = [];

  worksData.forEach((work) => {
    work.category.forEach((category_item) => {
      if(categories.indexOf(category_item) === -1){
        categories.push(category_item);
      }
    });
  });
  
  const categoryNavBtnHandler = e => {
    const targetTab = e.target.dataset.tab && e.target.dataset.tab !== '' ? e.target.dataset.tab.trim() : null;
    setWorkItemsFilter(targetTab);
  };

  return (
    <div className={style.works}>
      <Spacer />
      <h2 className='heading'>Recent Work</h2>
      <ul className={style.navigation}>
        <li className={!workItemsFilter || (workItemsFilter && workItemsFilter === 'all_works') ? `${style.active} btn-mobile` : 'btn-mobile'} data-tab='all_works' onClick={categoryNavBtnHandler}>All</li>
        {
          categories.map((item, index) => {
            return (<li key={index} className={(workItemsFilter && workItemsFilter === item) ? `${style.active} btn-mobile` : 'btn-mobile' } onClick={categoryNavBtnHandler} data-tab={item}>{item}</li>);
          })
        }
      </ul>
      <WorkItems filter={workItemsFilter}  />
    </div>
  );
};

export default Works;