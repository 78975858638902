import React from 'react';

import TimelineItem from './TimelineItem';

import style from './TimelineSections.module.css';

const TimelineSections = props => {
  return (
    <section className={style['timeline-section']}>
      <div className={style.year}>
        <h3>{props.item_data.year}</h3>
      </div>
      <div className={style['timeline-items']}>
        {props.item_data.timeline_items.map((data, index) => {
          return (<TimelineItem data={data} key={index} />);
        })}
      </div>
    </section>
  );
};

export default TimelineSections;