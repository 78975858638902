import React from 'react';

import { ReactComponent as Logo } from '../images/logo.svg';
import Spacer from '../ui/Spacer';
import style from './Footer.module.css';

const Footer = () => {
  const current_date = new Date();

  return (
    <footer className={style.footer}>
      <Spacer />
      <div>
        <Logo className={style.logo}/>
      </div>

      <div className={style.date}>
        {current_date.getFullYear()}
      </div>
    </footer>
  );
};

export default Footer;