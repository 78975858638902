const skillsData = [
  {
    name: 'Web Development',
    set: [
      {
        name: 'HTML, CSS & Javascript',
        icon: ['skill-icons-01.svg'],
        percent: 92,
      },
      {
        name: 'React JS and Redux',
        icon: ['skill-icons-02.svg'],
        percent: 90
      },
      {
        name: 'PHP',
        icon: ['skill-icons-03.svg'],
        percent: 80
      },
      {
        name: 'Database (MySQL)',
        icon: ['skill-icons-04.svg'],
        percent: 82
      },
      {
        name: 'WordPress',
        icon: ['skill-icons-05.svg'],
        percent: 90
      },
      {
        name: 'Shopify and Liquid',
        icon: ['skill-icons-06.svg'],
        percent: 87
      },
      {
        name: 'Webflow',
        icon: ['skill-icons-07.svg'],
        percent: 80
      },
      {
        name: 'Bootstrap',
        icon: ['skill-icons-08.svg'],
        percent: 90
      },
      {
        name: 'CodeIgniter',
        icon: ['skill-icons-09.svg'],
        percent: 80
      },
      {
        name: 'Jquery',
        icon: ['skill-icons-10.svg'],
        percent: 80
      }
    ]
  },
  {
    name: 'SEO',
    set: [
      {
        name: 'On-page SEO - Meta, Rich Snippet (Schema) and Google Search Console',
        icon: ['skill-icons-12.svg'],
        percent: 90,
      },
      {
        name: 'Off-page SEO',
        icon: ['skill-icons-12.svg'],
        percent: 45,
      }
    ]
  },
  {
    name: 'Graphics & Video',
    set: [
      {
        name: 'Photo Editing (Photoshop/Canva)',
        icon: ['skill-icons-13.svg'],
        percent: 75,
      },
      {
        name: 'Illustration and Logo Design (Adobe Illustrator)',
        icon: ['skill-icons-14.svg'],
        percent: 80
      },
      {
        name: 'Video Editing (Adobe Premiere Pro)',
        icon: ['skill-icons-15.svg'],
        percent: 75,
      },
      {
        name: 'Animation (Adobe After Effects)',
        icon: ['skill-icons-16.svg'],
        percent: 70
      }
    ]
  },
  {
    name: 'General',
    set: [
      {
        name: 'English (Written)',
        icon: ['skill-icons-17.svg'],
        percent: 90,
      },
      {
        name: 'English (Verbal)',
        icon: ['skill-icons-17.svg'],
        percent: 80,
      },
      {
        name: 'Windows/macOS',
        icon: ['skill-icons-18.svg'],
        percent: 90,
      },
      {
        name: 'Spreadsheet (Excel and Google Sheets)',
        icon: ['skill-icons-19.svg'],
        percent: 85,
      },
      {
        name: 'Data Communication and Networking',
        icon: ['skill-icons-20.svg'],
        percent: 60,
      },
      {
        name: 'Windows and Console Application (.NET C#)',
        icon: ['skill-icons-11.svg'],
        percent: 75
      },
      {
        name: 'Server Management',
        icon: ['skill-icons-21.svg'],
        percent: 50,
      },
      {
        name: 'Internet Marketing',
        icon: ['skill-icons-22.svg'],
        percent: 30,
      },
      {
        name: 'Firebase',
        icon: ['skill-icons-23.svg'],
        percent: 30
      },
      {
        name: 'Github',
        icon: ['skill-icons-24.svg'],
        percent: 85
      }
    ]
  }
];

export default skillsData;