import React, { useState } from 'react';

import Card from '../../ui/Card';
import Modal from '../../ui/Modal';

import style from './SkillSingle.module.css';

const SkillSingle = props => {
  const [skillDetails, setSkillDetails] = useState(false);

  const showSkillDetailsHandler = () => {
    setSkillDetails(true);
  };

  const closeSkillDetailsHandler = () => {
    setSkillDetails(false);
  };

  return (
    <React.Fragment>
      <div className={style['skills-item']} onClick={showSkillDetailsHandler}>
        <Card className={style['skill-card']} body={true}>
          <div className={style['skill-item-head']}>
            <span className={style['skill-name']}>{props.skillItemData.name}</span>
            <span className={style['skill-percent']}>{props.skillAverage}%</span>
          </div>
          <div className={style['skill-item-progress']}>
            <div className={style['skill-progress']}> <span style={ { width: props.skillAverage+'%' } }></span> </div>
          </div>
        </Card>
      </div>
        {
          skillDetails && (
            <Modal overlayClick={closeSkillDetailsHandler}>
              <div className={style['skill-details__set']}>
                <div className={style['skill-details__head']}>
                  <div className={style['skill-details__title']}>
                    <h4>{props.skillItemData.name}</h4>
                  </div>
                  <div className={style['skill-details__action']}>
                    <button className="btn btn-danger" onClick={closeSkillDetailsHandler}>close</button>   
                  </div>
                </div>
                <div className={style['skill-details__content']}>
                  {
                    props.skillItemData.set.map((skillSet, index) => {
                      let skillImage = null;
                      if(skillSet.icon !== undefined && skillSet.icon !== null){
                        if(typeof skillSet.icon == 'object'){
                          skillImage = skillSet.icon.map((image_item, index) => {
                            if(!image_item || image_item === ''){
                              return null;
                            }
                            return (<img key={index} src={require('./images/' + image_item.toString())} alt="Jeremy's Skills" />);
                          });
                        }else{
                          skillImage = (<img src={require('./images/' + skillSet.icon)} alt="Jeremy's Skills" />);
                        }
                      }

                      return (
                        <Card key={index} className={style['skill-card']} body={true}>
                          <div className={style['skill-details__t']}>
                            <div className={style['skill-details__t-c-1']}>
                              {skillImage && skillImage}
                            </div>
                            <div className={style['skill-details__t-c-2']}>
                              <div className={style['skill-item-head']}>
                                <span className={style['skill-name']}>{skillSet.name}</span>
                                <span className={style['skill-percent']}>{skillSet.percent}%</span>
                              </div>
                              <div className={style['skill-item-progress']}>
                                <div className={style['skill-progress']}> <span style={ { width: +skillSet.percent+'%' } }></span> </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      )
                    })
                  }
                </div>
              </div>
            </Modal>
          )
        }
    </React.Fragment>
  );
};

export default SkillSingle;