import React, { useState, useEffect } from 'react';

import Header from './app/Header';
import Timeline from './app/Timeline/Timeline';
import Skills from './app/Skills/Skills';
import Works from './app/Works/Works';
import Footer from './app/Footer';
import ThemeSwitcher from './ui/ThemeSwitcher';

function App() {
  const [isDark, setIsDark] = useState(false);

  // Check if browser theme changes
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    const newColorScheme = event.matches ? "DARK" : "LIGHT";
    
    if(!localStorage.getItem('jdt-theme')){
      if (newColorScheme === 'DARK') {
        setIsDark(true);
      }else{
        setIsDark(false);
      }
    }
  });

  // Toggle theme
  const toggleThemeHandler = () => {
    setIsDark((current_state) => {
      if(current_state){
        localStorage.setItem('jdt-theme', 'light');
      }else{
        localStorage.setItem('jdt-theme', 'dark');
      }
      return !current_state;
    });
  };

  // Pre-load theme
  useEffect(() => {
    if(localStorage.getItem('jdt-theme')){
      if (localStorage.getItem('jdt-theme') === 'dark') {
        setIsDark(true);
      }else{
        setIsDark(false);
      }
    }else{
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setIsDark(true);
      }
    }
  }, []);

  if(isDark){
    document.body.classList.add('dark-theme');
  }else{
    document.body.classList.remove('dark-theme');
  }

  return (
    <React.Fragment>
      <div className='container'>
        <Header />
        <Timeline />
        <Skills />
      </div>
      <div className='container container-large'>
        <Works />
      </div>
      <Footer />
      <ThemeSwitcher theme={isDark} toggleTheme={toggleThemeHandler} />
    </React.Fragment>
  );
}

export default App;
