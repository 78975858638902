import style from './ThemeSwitcher.module.css';

const ThemeSwitcher = props => {
  const buttonToggleHandler = () => {
    props.toggleTheme();
  };
  
  return (
    <div className={style['theme-switcher']}>
      <button className={`${style['theme-switcher__button']} ${props.theme && style['is-dark']}`} onClick={buttonToggleHandler}></button>
    </div>
  );
};

export default ThemeSwitcher;