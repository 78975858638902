import React from 'react';

const timelineData = [
  {
    year: 2020,
    timeline_items: [
      {
        description: (<p>Working remotely as a Support Moderator in <a href="https://rankmath.com/" target="_blank" rel="noreferrer nofollow">Rank Math</a></p>),
        image: 'rank-math.png',
        timestamp: 'November'
      }
    ]
  },
  {
    year: 2018,
    timeline_items: [
      {
        description: (<p>Received a <a href="https://www.testdome.com/cert/d6b3bcecb1a04f239adfc8dab9b2574f" target="_blank" rel="nofollow noreferrer">Certificate of Accomplishment</a> in TestDome (HTML/CSS, JavaScript with jQuery, and Bootstrap)</p>),
        timestamp: 'November'
      },
      {
        description: (<p>Working remotely as a WordPress Developer and System Administrator in SNC Marketing LLC</p>),
        image: 'sncmarketing.png',
        timestamp: 'October'
      }
    ]
  },
  {
    year: 2017,
    timeline_items: [
      {
        description: (<p>Working remotely with multiple clients (Fiverr, Onlinejobs.ph)</p>)
      }
    ]
  },
  {
    year: 2016,
    timeline_items: [
      {
        description: (<p>Attended Google DevFest (GDG Baguio) at the University of the Cordilleras and received the <a href="https://drive.google.com/open?id=0B4klwhy0OlV-NENNUWVSMU04Tzh0TW1DQnoySDEwMnBwXy04" target="_blank" rel="nofollow noreferrer">Certificate of Participation</a></p>),
        image: 'gdg.png',
        timestamp: 'November'
      },
      {
        description: (<p>Working as a Web Developer and Video Editor in AdSurf Media Inc.</p>),
        timestamp: 'June'
      }
    ]
  },
  {
    year: 2014,
    timeline_items: [
      {
        description: (<p>Working locally as a Technical Clerk in GVA Consultancy, Manpower, and General Services Inc.</p>)
      },
      {
        description: (
          <React.Fragment>
            <p>Completed On-the-job Training in Moog Controls Corporation - Philippine Branch</p>
            <ul>
                <li>Able to do daily tasks and created the "End of Shift Report" Application Software</li>
            </ul>
          </React.Fragment>
        ),
        image: 'moog.png',
        timestamp: 'July'
      },
      {
        description: (<p>Attended I.T. Seminar at the University of the Philippines Diliman and received the Certificate of Participation</p>),
        image: 'upitdc.png',
        timestamp: 'February'
      },
      {
        description: (<p>Started freelancing in <a href="https://fiverr.com/" target="_blank" rel="nofollow noreferrer">Fiverr</a> as a graphics designer and web developer</p>)
      }
    ]
  },
  {
    year: 2010,
    timeline_items: [
      {
        description: (<p>Started learning computer programming</p>),
        image: [
          'java.png', 'vs.png'
        ]
      },
      {
        description: (<p>Started college at Baguio College of Technology</p>),
        image: 'bct.png',
        timestamp: 'November'
      },
      {
        description: (<p>Graduated high school at Talisay Malayan Academy</p>)
      }
    ]
  },
  {
    year: '2000+',
    timeline_items: [
      {
        description: (<p>I touched a computer. My first computer</p>),
        image: 'pc.png'
      },
      {
        description: (<p>Started playing video games on game consoles. Good times :)</p>),
        image: 'ps.png'
      }
    ]
  },
  {
    year: '1993+',
    timeline_items: [
      {
        description: (<p>Pretty much a useless kid in the society</p>)
      },
      {
        description: (<p>Thus a baby was born</p>),
        image: 'baby.png',
        timestamp: 'July'
      }
    ]
  }
]

export default timelineData;