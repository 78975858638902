import React, { useState } from 'react';

import Card from "../../ui/Card";
import WorkItemDetails from './WorkItemDetails';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import style from "./WorkItem.module.css";

const WorkItem = (props) => {
  const [itemDetails, setItemDetails] = useState(false);
  
  const openItemModalHandler = () => {
    setItemDetails(true);
  };

  const workOverlayClickHandler = () => {
    setItemDetails(false);
  };

  let featuredImage = null;
  try {
    featuredImage = require('./images/' + props.workItemData.image);
  } catch (e) {
    // Default Image Here
  }

  // console.log(`%c Work Item is re-valuated! [${props.workItemData.title}]`, 'background-color:#fff;color:blue');
  return (
    <React.Fragment>
      <div className={style["tab-item"]} onClick={openItemModalHandler}>
        <Card className={style["work-card"]}>
          <div className={style["work-card-box"]}>
            <LazyLoadImage
              className={style["work-card-image"]}
              alt={props.workItemData.title}
              src={featuredImage}
            />
            <div className={style["work-overlay"]}></div>
            <div className={style["work-content"]}>
              <h4>{props.workItemData.title}</h4>
              <div className={style["work-categories"]}>
                {
                props.workItemData.category && (
                  props.workItemData.category.map((category_item, i) => {
                    return (
                      <label className={style.category} key={i}>
                        {category_item}
                      </label>
                    );
                  })
                )
                }
                {
                props.workItemData.tags &&
                  props.workItemData.tags.map((category_tag, i) => {
                    return (
                      <label className={style.category} key={i}>
                        {category_tag}
                      </label>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </Card>
      </div>
      { itemDetails && (<WorkItemDetails itemData={props.workItemData} firstImage={featuredImage} workOverlayClick={workOverlayClickHandler} />) }
    </React.Fragment>
  );
};

export default WorkItem;
