import React from 'react';

/*
======================================
Edit the settings here...
======================================
*/
const mySiteSettings = {
  onJobHunt: false
};

const contextSettings = React.createContext({ onJobHunt: null });
export const SettingsProvider = (props) => {
  return (
    <contextSettings.Provider
      value={
        {
          onJobHunt: mySiteSettings.onJobHunt
        }
      }
    >
      {props.children}
    </contextSettings.Provider>
  );
};

export default contextSettings;