import React, { useState, useContext } from 'react';

import contextSettings from './context-settings';

import Card from '../ui/Card';

import { ReactComponent as IconTwitter } from '../images/icons/social-twitter.svg';
import { ReactComponent as IconInstagram } from '../images/icons/social-instagram.svg';
import { ReactComponent as IconLinkedin } from '../images/icons/social-linkedin.svg';
import { ReactComponent as IconGithub } from '../images/icons/social-github.svg';
import { ReactComponent as IconFiverr } from '../images/icons/social-onlinejobs.svg';
import { ReactComponent as IconOnlinejobs } from '../images/icons/social-fiverr.svg';
import { ReactComponent as IconCall } from '../images/icons/call.svg';
import { ReactComponent as IconEmail } from '../images/icons/email.svg';
import { ReactComponent as IconDots } from '../images/icons/dots.svg';
import style from './Socials.module.css';

const Socials = () => {
  const theSettings = useContext(contextSettings);
  const [moreSocial, setMoreSocial] = useState(false);
  const socialShowMoreHandler = () => {
    setMoreSocial((current_state) => !current_state);
  };

  return (
    <ul className={style.socials}>
      <li className={style['socials__list']}><a href="https://twitter.com/jeremytalanay" className='btn-mobile' target="_blank" rel="noreferrer">{<IconTwitter className={style.icon} />}</a></li>
      <li className={style['socials__list']}><a href="https://www.instagram.com/jeremytalanay/" className='btn-mobile' target="_blank" rel="noreferrer">{<IconInstagram className={style.icon} />}</a></li>
      <li className={style['socials__list']}><a href="https://www.linkedin.com/in/jtalanay" className='btn-mobile' target="_blank" rel="noreferrer">{<IconLinkedin className={style.icon} />}</a></li>
      <li className={style['socials__list']}><a href="https://github.com/jeremytalanay" className='btn-mobile' target="_blank" rel="noreferrer">{<IconGithub className={style.icon} />}</a></li>
      {
        theSettings.onJobHunt && (
          <React.Fragment>
            <li className={style['socials__list']}><a href="tel:+639771782348" className='btn-mobile'>{<IconCall className={style.icon} />}</a></li>
            <li className={style['socials__list']}><a href="mailto:jeremytalanay@gmail.com?subject=Portfolio" className='btn-mobile'>{<IconEmail className={style.icon} />}</a></li>
          </React.Fragment>
        )
      }
      <li className={`${style['socials__list']} ${style['social__list-more']}`}>
        <span className='btn-mobile' onClick={socialShowMoreHandler}>
          {<IconDots className={style.icon} />}
        </span>
        {
          moreSocial && (
            <Card className={`${style['socials__more']} dark-theme_bg-dark`}>
              <ul>
                <li><a href="https://www.fiverr.com/jeremykun22" target="_blank" rel="noreferrer"><IconOnlinejobs className={style.icon} /> Fiverr</a></li>
                <li><a href="https://www.onlinejobs.ph/jobseekers/info/134413" target="_blank" rel="noreferrer"><IconFiverr className={style.icon} /> Onlinejobs.ph</a></li>
              </ul>
            </Card>
          )
        }
      </li>
    </ul>
  );
};

export default Socials;