import worksData from './works-data';
import WorkItem from './WorkItem';

import style from './WorkItems.module.css';

const WorkItems = props => {

  // console.log('%c Work Items is re-valuated!', 'background-color:#fff;color:red');
  return (
    <div className={style['tab-content']}>
      {
        worksData.map((item, index) => {
          if(props.filter && props.filter !== 'all_works'){
            if(item.category.includes(props.filter)){
              return (<WorkItem key={index} workItemData={item} />);
            }else{
              return ("");
            }
          }
          
          return <WorkItem key={index} workItemData={item} />;
        })
      }
    </div>
  );
};

export default WorkItems;