import React from 'react';

import style from './TimelineItem.module.css';

const TimelineItem = props => {
  let timelineImage = null;
  if(props.data.image !== undefined && props.data.image !== null){
    if(typeof props.data.image == 'object'){
      timelineImage = props.data.image.map((image_item, index) => {
        return (<img key={index} src={require('./images/' + image_item.toString())} alt="Jeremy's Timeline" />);
      });
    }else{
      timelineImage = (<img src={require('./images/' + props.data.image)} alt="Jeremy's Timeline" />);
    }
  }

  return (
    <div className={style['timeline-item']}>
      {props.data.description}
      {timelineImage && timelineImage}
      <h4>{props.data.timestamp}</h4>
    </div>
  );
};

export default TimelineItem;