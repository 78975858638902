import Modal from "../../ui/Modal";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import style from "./WorkItemDetails.module.css";

const WorkItemDetails = props => {
  // console.log('%c Work Item Modal is re-valuated!', 'background-color:#fff;color:green');

  const overlayClickHandler = () => {
    props.workOverlayClick();
  };

  return (
    <Modal overlayClick={overlayClickHandler}>
      <div className={style['work-item__details']}>
        <div className={style['work-item__head']}>
          <div className={style['work-item__info']}>
            <h4>{props.itemData.title}</h4>
          </div>
          <div className={style['work-item__actions']}>
            { props.itemData.url && ( <a href={props.itemData.url} className="btn" rel="noreferrer nofollow" target="_blank">Visit URL</a> ) }
            <button className="btn btn-danger" onClick={overlayClickHandler}>close</button>   
          </div>
        </div>
        <div className={style['work-item__description']}>
          <div className={style['work-item__date']}>
            <span>{props.itemData.date}</span>
          </div>
          <p>{props.itemData.description}</p>
        </div>
        <div className={style['work-item__categoriestags']}>
          {
            props.itemData.category && (
              props.itemData.category.map((category_item, i) => {
                return (
                  <label className={`${style.category} dark-theme_bg-white dark-theme_txt-dark`} key={i}>
                    {category_item}
                  </label>
                );
              })
            )
          }
          {
          props.itemData.tags &&
            props.itemData.tags.map((category_tag, i) => {
              return (
                <label className={`${style.category} dark-theme_bg-white dark-theme_txt-dark`} key={i}>
                  {category_tag}
                </label>
              );
            })
          }
        </div>
        <div className={style['work-item__images']}>
          <LazyLoadImage
            className={style['work-item__img-container']}
            alt={props.itemData.title}
            src={props.firstImage}
          />
          {
            props.itemData.images.map((img, index) => {
              let moreImage = null;
              try {
                moreImage = require('./images/' + img);
              } catch (e) {
                // Default Image Here
              }

              if(moreImage){
                return (
                  <LazyLoadImage
                    key={index}
                    className={style['work-item__img-container']}
                    alt={props.itemData.title}
                    src={moreImage}
                  />
                )
              }else{
                return ""
              }
            })
          }
        </div>
      </div>
    </Modal>
  )
};

export default WorkItemDetails;