import React, { useContext } from 'react';

import contextSettings from './context-settings';

import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as IconDownload } from '../images/icons/download-file.svg';
import Spacer from '../ui/Spacer';
import Socials from './Socials';
import Resume from './_online_resume_talanay_2020.pdf';

import style from './Header.module.css';

const Header = () => {
  const theSettings = useContext(contextSettings);

  return (
    <header className={style.header}>
      <Spacer small={true} />
      {<Logo className={`${style.logo} dark-theme_white-fill`} />}
      <h1>Jeremy Talanay</h1>
      <p>A former baby / Web Developer</p>
      <Socials />
      {
        theSettings.onJobHunt && (
          <a className={`btn ${style['download-button']}`} href={Resume} target="_blank" rel="noreferrer nofollow"><span>Resume</span> <IconDownload className={style['download-icon']} /></a>
        )
      }
      <Spacer small={true} />
    </header>
  );
};

export default React.memo(Header);
