const worksData = [
  /* Web */

  /*
  {
    title: '',
    description: '',
    date: '',
    image: '',
    images: [

    ],
    url: '',
    category: [
      ''
    ],
    tags: [
      '',
    ]
  },
  */
  {
    title: 'Zen Dev Tools Tally Counter',
    description: 'A very simple online tally counter tool that can add multiple counters. This is a React JS project integrated to WordPress',
    date: '2023',
    image: 'web/2023_tally_counter/zendevtools.jpg',
    images: [
      
    ],
    url: 'https://zendevtools.com/tools/tally-counter/',
    category: [
      'React JS', 'WordPress'
    ],
    tags: [
      'Personal'
    ]
  },
  {
    title: 'Jeremy Talanay Portfolio',
    description: 'My own portfolio website proudly made using React JS',
    date: 'Q3 of 2022',
    image: 'web/2022_jeremy/social-banner.jpg.png',
    images: [
      'web/2022_jeremy/Screenshot 2022-09-03 112507.png'
    ],
    url: 'https://jeremytalanay.com',
    category: [
      'Web', 'React JS'
    ],
    tags: [
      'Personal',
      'Portfolio'
    ]
  },
  {
    title: 'Cleaness',
    description: 'A website for private cosmetics and pedicure school. I created this website with WordPress, along with custom booking/appointment system for their clinic. ',
    date: '2022',
    image: 'web/2022_cleaness/cleaness.jpg',
    images: [

    ],
    url: 'https://cleaness.net/',
    category: [
      'Web', 'WordPress', 'PHP'
    ],
    tags: [
      'Design',
      'Booking System'
    ]
  },
  {
    title: 'Outpaw',
    description: 'A landing page dedicated for an animal chip scanner product.',
    date: '2022',
    image: 'web/2022_outpaw/outpaw.jpg',
    images: [

    ],
    url: 'https://outpaw.io/',
    category: [
      'Web',
      'Webflow'
    ],
    tags: [
      'Design',
      'SEO'
    ]
  },
  {
    title: 'tierchipleser.de',
    description: 'Shopify shop offering chip scanner for animals',
    date: '2021',
    image: 'web/2021_tierchipleser/screencapture-tierchipleser-de-2023-02-04-21_32_51.jpg',
    images: [

    ],
    url: 'https://tierchipleser.de/',
    category: [
      'Shopify'
    ],
    tags: [
      'Design',
      'Custom Sections',
      'SEO'
    ]
  },
  {
    title: 'BBI Professionals',
    description: '',
    date: 'October 2020', /* 2020-10-20 */
    image: 'web/2020-10-20_bbi-professionals/screencapture-shopbbi-2020-10-20-22_14_56.png',
    images: [

    ],
    url: '',
    category: [
      'Web', 'Shopify'
    ],
    tags: [
      'Design'
    ]
  },
  {
    title: 'Pureair',
    description: '',
    date: 'September 2020', /* 2020-09-22 */
    image: 'web/2020-09-22_pureair/8ab9d752-65ab-497f-bb39-e165867d6ada_rw_1920.jpg',
    images: [
      
    ],
    url: '',
    category: [
      'Web', 'Shopify'
    ],
    tags: [
      'Design',
      'Mobile Responsive'
    ]
  },
  {
    title: 'SMPL Society',
    description: 'A shop that offers fashionable masks.',
    date: 'September 2020', /* 2020-09-14 */
    image: 'web/2020-09-14_smpl-society/0bc196e3-62c4-4dfe-b20f-51c99f581404.jpg',
    images: [
      
    ],
    url: '',
    category: [
      'Web', 'Shopify'
    ],
    tags: [
      'Design',
      'Mobile Responsive'
    ]
  },
  {
    title: 'Home and Health Haven',
    description: 'Home and Health Products',
    date: 'August 2020', /* 2020-08-06 */
    image: 'web/2020-08-06_home-and-health-haven/934a790c-2a29-4a10-8af3-e781e26c8a03_rw_1920.jpg',
    images: [
      'web/2020-08-06_home-and-health-haven/logo.png'
    ],
    url: '',
    category: [
      'Web', 'Shopify'
    ],
    tags: [
      'Content',
      'Setup',
      'Logo'
    ]
  },
  {
    title: 'HireDome',
    description: 'A Marketplace for Freelance Services.',
    date: '2020',
    image: 'web/2020-02-01-hiredome/30dcdbfd-af9b-413c-86e5-83868e6ae76e_rw_600.jpg',
    images: [
      'hiredome-logo-2020-02.png',
      'hiredome-logo-2020-03.png',
      'hiredome-logo-2020-09.png',
      'hiredome-logo-2020-14.png',
      'screencapture-hiredome-2020-10-14-15_55_04.jpg'
    ],
    url: '',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Design',
      'Concept'
    ]
  },
  {
    title: 'WC Pipeline',
    description: 'Oil & Gas experts',
    date: '2019',
    image: 'web/2019_wc-pipeline/2f2621de-eff2-44cd-a2c9-230a7b83358b_rw_1920.jpg',
    images: [
      'eb/2019_wc-pipeline/338c42d0-a44c-482f-9a23-fb74feae2708_rw_1920.png'
    ],
    url: 'http://wcpipeline.com/',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Blog',
      'Logo'
    ]
  },
  {
    title: 'One Agenda',
    description: '',
    date: '2019',
    image: 'web/2019_one-agenda/6ebea11c-4e28-4ee9-9eeb-3e8e42ac213f_rw_1920.jpg',
    images: [
      
    ],
    url: '',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Internet Marketing',
      'Business',
      'Design'
    ]
  },
  {
    title: 'Live X Maintain',
    description: 'Personalised Phone cases, Suitcases & Coffee Cups based in the UK.',
    date: '2019',
    image: 'web/2019_live-x-maintain/b3eff64a-3bc3-4479-a397-3e5bfde57ba7.jpg',
    images: [
      
    ],
    url: 'https://livexmaintain.com/',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Customization',
      'Content'
    ]
  },
  {
    title: 'Futebol Finance',
    description: '',
    date: '2019',
    image: 'web/2019_futebol-finance/39d71325-1f35-4968-99cc-7bdf8fe0c188_rw_1920.jpg',
    images: [
      'web/2019_futebol-finance/2bb32291-81f6-4ad3-a68c-a3789e58f8e0_rw_1200.png'
    ],
    url: 'https://futebolfinance.com/',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Blog',
      'Logo'
    ]
  },
  {
    title: 'Custo Grows',
    description: 'Baby clothing shop based in the UK.',
    date: '2019',
    image: 'web/2019_custo-grows/fa9e9298-1868-408e-9ac7-d6c86898c244_rw_1920.jpg',
    images: [
      
    ],
    url: 'https://custo-grows.com/',
    category: [
      'Web', 'Shopify'
    ],
    tags: [
      'Customization',
      'Content'
    ]
  },
  {
    title: 'Canto Nuovo',
    description: '',
    date: '2019',
    image: 'web/2019_canto-nuovo/198405e9-3812-43eb-a6db-179b30882f15_rw_1920.jpg',
    images: [
      'web/2019_canto-nuovo/06dea24c-ee1e-4e1a-9359-7e1cbae73aa3_rw_600.png',
      'web/2019_canto-nuovo/18a55dfa-7007-47ee-9cc6-3f5661de71aa_rw_1200.png',
      'web/2019_canto-nuovo/855349cc-7e77-4f38-ac6f-dced906cc30a_rw_1200.png'
    ],
    url: '',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Blog',
      'Logo'
    ]
  },
  {
    title: 'Best Rated Home Air Conditioning',
    description: '',
    date: '2019',
    image: 'web/2019_best-rated-home-air-conditioning/78f44a12-f763-4243-82e0-c8f492c69a58_rw_1920.jpg',
    images: [
      'web/2019_best-rated-home-air-conditioning/e5b0b7d4-0bea-45b3-9e8a-27ab8fab3ac8_rw_600.png'
    ],
    url: '',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Blog',
      'Business'
    ]
  },
  {
    title: 'AspxNet',
    description: 'IT Blog based in Italy',
    date: '2019',
    image: 'web/2019_aspxnet/19fb8ec8-5ab3-4c7a-9301-d29bb28a0b55_rw_1920.jpg',
    images: [
      'web/2019_aspxnet/4b3ffbc3-9516-409e-a987-03a578db2e77_rw_600.png',
      'web/2019_aspxnet/81cc91b1-57d0-4f48-8cdb-44f3173a725e_rw_1200.jpg',
      'web/2019_aspxnet/ae9523e6-bad8-4a6b-b5eb-f328a77dcb0c_rw_1920.png'
    ],
    url: '',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Blog',
      'Logo'
    ]
  },
  {
    title: 'American Pacific Horticulture',
    description: '',
    date: '2019',
    image: 'web/2019_american-pacific-horticulture/d44ed40c-5313-479d-a440-4d3ba89411a8_rw_1920.jpg',
    images: [
      'web/2019_american-pacific-horticulture/41b745f8-9a0e-4bd8-a913-b08bd2e98257_rw_1200.png',
      'web/2019_american-pacific-horticulture/c803370b-31b2-45aa-8632-4490de7feb22_rw_600.png'
    ],
    url: '',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Blog',
      'Logo'
    ]
  },
  {
    title: 'VI Connections Social Guide',
    description: 'Provides social guide in U.S. Virgin Islands.',
    date: '2018',
    image: 'web/2018_vi-connections-social-guide/ffe010fd-7b0d-430b-b88e-55c2c798d78c_rw_1920.jpg',
    images: [
      'web/2018_vi-connections-social-guide/03a1493c-2e5b-4626-9281-4152a64d36bd_rw_1920.png'
    ],
    url: '',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Community',
      'Magazine'
    ]
  },
  {
    title: 'VI Connections Now Playing',
    description: 'Provides updated cinema movie schedules in U.S. Virgin Islands area.',
    date: '2018',
    image: 'web/2018_vi-connections-now-playing/1a112fa2-4791-45e8-8f6c-b41b83fe4bae_rw_1920.jpg',
    images: [
      'web/2018_vi-connections-now-playing/a1466829-cf73-401c-860b-708a22eb13d5_rw_1920.png'
    ],
    url: '',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Design',
      'Content'
    ]
  },
  {
    title: 'VI Connections Directory',
    description: 'Directory/Classifieds site for Virgin Islands area.',
    date: '2018',
    image: 'web/2018_vi-connections-directory/9ffbacd9-3a2b-4739-90d3-47b15528be19_rw_1920.png',
    images: [
      'web/2018_vi-connections-directory/83ad0e11-ccc1-407f-954c-1a16731d96b1_rw_1920.jpg',
      'web/2018_vi-connections-directory/7f659394-758a-4d88-8271-89411474ddfb_rw_1920.png'
    ],
    url: '',
    category: [
      'Web'
    ],
    tags: [
      'PHP',
      'Brilliant Directory',
      'Design'
    ]
  },
  {
    title: 'SNC Marketing LLC',
    description: 'A company based in U.S. Virgin Islands that offers internet marketing services.',
    date: '2018',
    image: 'web/2018_snc-marketing/559d3ce5-d849-49b3-a20a-e26c26177d08_rw_600.jpg',
    images: [
      'web/2018_snc-marketing/2e087d29-e62c-40b3-b369-38ce3814e6ec_rw_1920.jpg'
    ],
    url: '',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Internet Marketing',
      'Landing Page'
    ]
  },
  {
    title: 'PineappleMood',
    description: 'An online gift shop based in Israel',
    date: '2018',
    image: 'web/2018_pineapplemood/3f1bb671-19d2-4a96-87d4-15206a9c7bb4_rw_600.jpg',
    images: [
      'web/2018_pineapplemood/bd4c0dfb-0065-411a-a43d-076efbc312a2_rw_1920.jpg'
    ],
    url: 'https://pineapplemood.com/',
    category: [
      'Web', 'WordPress'
    ],
    tags: [
      'Woocommerce',
      'Design'
    ]
  },
  {
    title: 'Mashtees - T-Shirts and Accessories',
    description: '',
    date: '2018',
    image: 'web/2018_mash-tees/3e039f0d-da6f-426f-b819-d011a36576dc_rw_600.jpg',
    images: [
      
    ],
    url: '',
    category: [
      'Web', 'Shopify'
    ],
    tags: [
      
    ]
  },
  {
    title: 'Benguet Agri-Pinoy Trading Center',
    description: 'A PHP based website with price tracker for wholesale vegetable products and agriculture services.',
    date: '2016',
    image: 'web/2016_baptc/1885fac8-6e59-4190-b485-951c1488a099_rw_600.jpg',
    images: [
      'web/2016_baptc/1c0905fa-6c63-4937-a00a-65ade42ae848_rw_1920.jpg',
      'web/2016_baptc/1c6137f0-b465-4137-99e3-ea4a8d641e4e_rw_600.jpg'
    ],
    url: '',
    category: [
      'Web', 'PHP'
    ],
    tags: [
      'Design',
      'Backend',
      'CMS'
    ]
  },
  {
    title: 'ZaPearl',
    description: '',
    date: '2015',
    image: 'web/2015_zapearl/zapearl.jpg',
    images: [
      
    ],
    url: '',
    category: [
      'Web'
    ],
    tags: [
      'Concept',
      'Design',
      'Responsive'
    ]
  },
  {
    title: 'Rave App',
    description: 'Landing page for an App.',
    date: '2015',
    image: 'web/2015_rave-app/rave-app.jpg',
    images: [
      
    ],
    url: '',
    category: [
      'Web'
    ],
    tags: [
      'Design'
    ]
  }
];

export default worksData;


