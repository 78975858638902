import React from 'react';

import skillsData from './skills-data';

import SkillSingle from './SkillSingle';
import Spacer from '../../ui/Spacer';

import style from './Skills.module.css';

const Skills = () => {
  return (
    <div className={style.skills}>
      <Spacer />
      <h2 className='heading'>Skills</h2>
      <div className={style['skills-wrap']}>
        {skillsData.map((skill, index) => {
          let count = 0;
          let totalSkill = 0;
          for(const skillSetItem of skill.set){
            count++;
            totalSkill = totalSkill + skillSetItem.percent;
          }
          const averagePercentage = Math.round(totalSkill / count);

          // console.log(averagePercentage);
          return ( <SkillSingle key={index} skillItemData={skill} skillAverage={averagePercentage} /> )
        })}
      </div>
    </div>
  );
};

export default Skills;